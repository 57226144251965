import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CoreMedia UAPI in TypeScript/Node`}</h1>
    <p>{`This documentation gives an overview of how to use the TypeScript version of CoreMedia's Unified API (UAPI) in Node.js to
interact with CoreMedia Content Cloud via Studio's REST API. It includes instructions for setup and code examples for
logging in, reading content, creating content, updating content, querying content, and handling content issues.
The documentation emphasizes strong typing and asynchronous capabilities.`}</p>
    <h2>{`Use Cases`}</h2>
    <ul>
      <li parentName="ul">{`Integrating CoreMedia with other systems`}</li>
      <li parentName="ul">{`Custom web-based extensions for CoreMedia`}</li>
      <li parentName="ul">{`Automation of custom tasks for your own use cases`}</li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul">{`To learn more, have a look at these examples of using `}<a parentName="li" {...{
          "href": "https://github.com/coremedia-contributions/content-scripting-with-typescript-examples?tab=readme-ov-file"
        }}>{`CoreMedia Unified API from TypeScript and Node.js`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      